import React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import surveyJson from "./survey.json";
import surveyTheme from "./survey_theme";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import * as SurveyCore from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";
import { autocomplete } from "surveyjs-widgets";
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import { nouislider } from "surveyjs-widgets";
import "nouislider/distribute/nouislider.css";
import Prompt from './prompt';

const { v4: uuidv4 } = require("uuid");

//slider
nouislider(SurveyCore);

// autocomplete pour la liste des médicaments
window["$"] = window["jQuery"] = $;
require("easy-autocomplete/dist/jquery.easy-autocomplete.js");
autocomplete(SurveyCore);
const SURVEY_ID = uuidv4();


function submitSurveyData(data) {

  const ENDPOINT_URL = "https://iadiagme.eu/runSurveyPDF";

  fetch(ENDPOINT_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((error) => console.error(error));
}

function App() {
  const [searchParams] = useSearchParams();
  const survey = new Model(surveyJson);

  // Appliquer le thème CSS importé
  survey.applyTheme(surveyTheme);

  survey.locale = searchParams.get("lang");

  var converter = new Converter();
  survey.onTextMarkdown.add(function (survey, options) {
    var str = converter.makeHtml(options.text);
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    options.html = str;
  });

  survey.onUploadFiles.add((_, options) => {
    let data = new FormData();
    console.log("upload");
    options.files.forEach((file) => {
      //data.append('file', file);
      const newFileName = `${SURVEY_ID}.pdf`;
      const newFile = new File([file], newFileName, { type: file.type });

      data.append("file", newFile);
    });

    axios.post("https://iadiagme.eu/uploadSingleFile", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        options.callback(
          options.files.map((file) => {
            const newFileName = `${SURVEY_ID}.pdf`;
            const newFile = new File([file], newFileName, { type: file.type });

            return {
              file: newFile,
              content: data[newFileName],
            };
          })
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
        options.callback([], ["An error occurred during file upload."]);
      });
  });

  survey.onComplete.add((survey) => {
    let prompt = searchParams.get("prompt");
    survey.setValue("prompt", prompt);

    let lang = searchParams.get("lang");
    survey.setValue("lang", lang);

    survey.setValue("surveyId", SURVEY_ID);
    console.log(JSON.stringify(survey.data, null, 3));
    submitSurveyData(survey.data);
  });

  survey.onValueChanged.add(function (sender, options) {
    if (options.name === "question18") {
      var acceptTerms = sender.getValue("question18");
      // alert(acceptTerms);
       if (acceptTerms == true) {
        $(".sd-navigation__complete-btn").css("visibility", "visible");
       } else {
        $(".sd-navigation__complete-btn").css("visibility", "hidden");
       }
    }
  });

  if (searchParams.get("activeprompt") == 1)
  return <Prompt></Prompt>;
  else
  return <Survey model={survey} />;
}

export default App;
